import CryptoJS from 'crypto-js'

// Convert hexadecimal keys to CryptoJS format
// const ENC_KEY = CryptoJS.enc.Utf8.parse('bf3c199c2470cb477d907b1e0917c17b') // 32-byte key
// const IV = CryptoJS.enc.Utf8.parse('5183666c72eec9e4') // 16-byte IV
const ENC_KEY = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_AES_KEY)
const IV = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_AES_IV)

export const encrypt = (data) => {
  try {
    const text = typeof data === 'string' ? data : JSON.stringify(data)

    const encrypted = CryptoJS.AES.encrypt(text, ENC_KEY, {
      iv: IV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    })

    return encrypted.toString()
  } catch (error) {
    console.error('Encryption error:', error)
    return null
  }
}

export const decrypt = (cipherText) => {
  try {
    const decrypted = CryptoJS.AES.decrypt(cipherText, ENC_KEY, {
      iv: IV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    })

    return decrypted.toString(CryptoJS.enc.Utf8)
  } catch (error) {
    console.error('Decryption error:', error)
    return null
  }
}
